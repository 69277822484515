@font-face {
  font-family: mainFont;
  src: url('../src/assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: boldFont;
  src: url('../src/assets/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: lightItalicFont;
  src: url('../src/assets/fonts/Roboto/Roboto-LightItalic.ttf');
}

h1,h2,h3,h4,h5,p {
  font-family: mainFont;
}

p {
  white-space: break-spaces;
}

.navbar-container, .mobile-navbar-container {
  min-width: 100vw;
  max-width: 80px;
  min-height: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: white;
  border-bottom: solid black 0.5px;
}

ul {
  text-decoration: none;
  list-style: none;
}

.navbar__links-list {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.navbar__links {
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
}

.navbar__header-container {
  height: 100%;
  
}

.navbar-container > * {
  flex: 1
}

.navbar__header {
  margin-left: 4rem;
  color: #67A8F0;
  border-left: solid black 0.5px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2rem;
}

.navbar__audio-player {
  border: solid black 1.5px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
}

.navbar__audio-player__range-slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border: 0;
  background: #000000;
  cursor: pointer;
}


.navbar__audio-player__range-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  border-radius: 10px;
  opacity: 0.7;
  transition: opacity .15s ease-in-out;
  margin-bottom: 0.5rem;
}


.navbar__audio-player__title {
  font-size: 0.9rem;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}


.navbar__play-icon-container {
  display: flex;
  align-items: center;
}

.navbar__audioplayer-title-duration-container {
  display: flex;
  justify-content: space-between;
}

.play-icon {
  height: 30px; 
  cursor: pointer;
}

.sections-container {
  margin-top: 80px;
}

.newscardcontainer-container, .audio-card-container {
  display: flex;
  justify-content: flex-start;
  gap: 3rem;
  margin-left: 3rem;
}

.audio-card-container {
  flex-wrap: wrap;
  row-gap: 1rem;
}

.navbar__img {
  max-height: 100px;
}

.newscard-container, .audio-container, .mobile-newscard-container {
  min-width: 300px;
  max-width: 300px;
  /* box-shadow: 2px 2px 20px rgb(141, 141, 141); */
}

.audio-container {
  text-overflow: ellipsis;
}

.newscard__img, .audio__img, .audio__texts {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  border: solid black 0.5px;
  /* border-radius: 0.2rem; */
}

.audio__img {
  object-fit: cover;
  width: 300px;
  aspect-ratio: 1 / 1;
  border: none;
}

.audio__texts {
  padding-bottom: 1rem;
}

.newscard__element {
  padding: 0 1rem;
}

.newscard__date {
  font-family: boldFont;
}

.newscard__description {
  font-size: 0.9rem;
}

details > div {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.summary-container {
  height: 80px;
  display: flex;
  align-items: center;
  transition: 500ms;
  cursor: pointer;
}

.mobile-summary-container {
  height: 40px;
  display: flex;
  align-items: center;
  transition: 500ms;
  cursor: pointer;
  font-size: 0.8rem;
}

.mobile-summary__header {
  margin-left: 2rem;
}

.summary-container:hover {
  color: #67A8F0;
}

.details-container[open] > .summary-container {
  background-color: #67a7f01c;
  border-bottom: solid black 0.5px;
  color: black;
  font-weight: 600;
}

.details-container[open] {
  border-bottom: solid black 0.5px;
  border-top: solid black 0.5px;
}

.mobile-details-container[open] .mobile-summary__header {
    border-bottom: solid black 1px;
    font-weight: 600;
}

.summary__header {
  margin-left: 6rem;
  cursor: pointer;
}


a {
  text-decoration: none;
  color: black;
  font-family: boldFont;
}

.audio-player {
  background-color: black;
  border: solid black 1px;
  width: 299px;
}

.audio-list-container, .audio-table-outer-container {
  display: flex;
  /* border-top: solid black 0.5px */
}

.audio-list-container > * {
    min-height: 400px
}

.audio-table-container {
  flex: 2;
  /* border-right: solid black 0.5px; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.audio-details-container {
  flex: 5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
  gap: 1rem;
}

.audio-details__desciption {
  width: 60%;
  text-align: center;
  border-top: solid black 0.5px;
  border-bottom: solid black 0.5px;
  padding: 1rem;
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.audio__table {
  width: 100%;
}

.table__row {
  cursor: pointer;
  height: 50px;
}

.table__text {
  padding-left: 6rem;
  font-family: mainFont; 
}

.selected {
  /* background-color: #67a7f01c; */
  font-weight: 600;
}

.selected td {
  /* border-right: solid black 0.5px; */
  border-top: solid black 0.5px;
  border-bottom: solid black 0.5px;
}

.audio-player__song-title {
  font-size: 0.7rem;
}

.list__audio-player {
  width: 300px;
  display: flex;
  justify-content: center;
}

.list__play-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table__text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.hidden {
  visibility: hidden;
}

.song-title {
  font-family: mainFont;
  
}

.song-info {
  font-size: 0.7rem;
  font-family: lightItalicFont;
}

.about-container {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-container p {
  font-size: 0.9rem;
}

.about-info {
  width: 25%;
  text-align: center;
  line-height: 2rem;
  font-size: 0.9rem;
  border-bottom: solid black 0.5px;
  height: 100px;
}

.about-info span {
  font-weight: 600;
  font-size: 0.9rem;
}

.about-activities {
  margin-top: 3rem;
  display: flex;
  width: 100%
}

.about-activities > * {
  flex: 1;
  padding-left: 10rem;
  padding-right: 10rem;
}

.contact-container {
  min-height: 300px;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.mobile-audio-list-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: auto;
}

@media screen and (min-width: 500px) {
  .mobile-audio-list-container {
    display: none;
  }
  .mobile-footer-container {
    display: none;
  }
  .mobile-navbar-container {
    display: none;
  }
  .mobile-navbar-audio-player {
    display: none;
  }
}


@media screen and (max-width: 500px) {
  /* .mobile-footer-container {
    position: fixed; 
    top: 80px;  
    width: 100dvw;
    position: relative;
    bottom: 0;
    height: 100px;
    border-top: solid black 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #67a7f01c;
  } */
  
  .mobile-navbar-container {
    flex-direction: column;
    height: 100px
  }

  .sections-container {
    margin-top: 100px;
  } 

  .mobile-navbar__header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-navbar-audio-player {
    display: flex;
    align-items: center;
    gap: 0.6rem
  }
  
  .navbar__audioplayer-title-duration-container p {
    font-size: 0.8rem;
  }

  .mobile-navbar-container > div {
    flex: 1;
  }

  .audio-table-outer-container {
    display: none;
  }

  .navbar-container {
    display: none;
  }

  .summary-container {
    justify-content: center;
  }
  .newscardcontainer-container {
    flex-direction: column;
    margin-left: 0;
    align-items: center;
  }
  /* .newscard__img {
    display: none;
  } */
  .about-info {
    width: 80%;
    padding-bottom: 2rem;
  }
  .about-activities {
    flex-direction: column;
  }
  .about__concerts, .about__publications {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  details summary::-webkit-details-marker {
    display:none;
  }
  .navbar__header {
    color: black;
  }
  .summary__header {
    font-weight: 400;
    margin-left: 0
  }
  .details-container[open] .summary__header {
    font-weight: 600 !important;
  }
}

@media screen and (max-width: 650px) {
    .table__text {
      padding-left: 2rem
    }
}